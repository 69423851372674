











































































































































































































































  import { debounceInput, isMobileScreenWidth } from '@/shared/helpers'
  import { Report, IReport } from '@/shared/model/report.model'
  import { UserRole } from '@/shared/model/user.model'
  import companyService from '@/shared/services/companyService'
  import reportService from '@/shared/services/reportService'
  import userService from '@/shared/services/userService'
  import axios from 'axios'
  import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'

  export default Vue.extend({
    name: 'ReportTable',
    components: {
      ReportDetails: () => import('./ReportDetails.vue'),
    },
    props: {
      disableCreateReports: {
        type: Boolean,
        default: () => false,
      },
    },
    data () {
      return {
        options: {} as any,

        reportSearch: '',
        debounce: undefined as number | undefined,

        breadcrumbItems: [] as any[],

        selectedReport: new Report(),
        report: undefined as IReport | undefined,
        isMobile: isMobileScreenWidth(),
        userProfileRouteIsUsed: !!this.$route.params.uuid,

        canCreateReports: false,

        selectedDate: '',
        datePickerVisible: false,
        dateInput: '',
      }
    },
    computed: {
      ...mapGetters({
        account: 'account',
        company: 'company',
        reports: 'reportStore/getList',
        totalReports: 'reportStore/getTotal',
        totalVehicles: 'companyVehicleStore/getTotal',
      }),
      reportHeaders (): any {
        return [
          {
            text: this.$t('date'),
            value: 'date',
          },
          {
            text: this.$t('tester'),
            value: 'user',
          },
          {
            text: this.$t('licence-plate'),
            value: 'companyVehicleObject.licencePlate',
          },
          {
            text: this.$t('owner'),
            value: 'companyVehicleObject.owner',
          },
          {
            text: this.$t('report'),
            value: 'type',
          },
          {
            sortable: false,
            text: this.$t('valid'),
            value: 'valid',
            align: 'end',
          },
          {
            sortable: false,
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
          },
        ]
      },
      reportAdminHeaders (): any {
        return [
          {
            text: this.$t('date'),
            value: 'date',
          },
          {
            text: this.$t('company'),
            value: 'companyObject.name',
          },
          {
            text: this.$t('tester'),
            value: 'user',
          },
          {
            text: this.$t('licence-plate'),
            value: 'companyVehicleObject.licencePlate',
          },
          {
            text: this.$t('owner'),
            value: 'companyVehicleObject.owner',
          },
          {
            text: this.$t('report'),
            value: 'type',
          },
          {
            sortable: false,
            text: this.$t('valid'),
            value: 'valid',
            align: 'end',
          },
          {
            sortable: false,
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
          },
        ]
      },
      userIsSystemAdmin () {
        return userService.userIsSystemAdmin()
      },
      dashboardMode (): boolean {
        return this.$route.name === 'dashboard'
      },
      adminReportsMode (): boolean {
        return this.$route.name === 'all-reports'
      },
    },
    watch: {
      options: {
        async handler () {
          this.getAllReportsWithVDataTable()
        },
      },
      reportSearch: {
        async handler () {
          this.debounce = debounceInput(this.debounce, this.getAllReportsWithVDataTable)
          this.options.page = 1
        },
      },
      $route: function () {
        this.getReportData()
        this.getAllReportsWithVDataTable()
      },
      company: {
        async handler () {
          this.getCompanyVehicles()
        },
      },
      selectedDate: {
        async handler () {
          this.datePickerVisible = false
          this.dateInput = this.selectedDate
          this.getAllReportsWithVDataTable()
          this.options.page = 1
        },
      },
      dateInput: {
        async handler () {
          if (!this.dateInput || !this.dateInput.length) {
            this.selectedDate = ''
          }
        },
      },
    },
    async created () {
      this.getReportData()
      this.processCanCreateReports()
      this.getCompanyVehicles()
    },

    methods: {
      ...mapActions('reportStore', {
        deleteReport: 'deleteItem',
      }),
      selectDate () {
        this.datePickerVisible = false
        this.getAllReportsWithVDataTable()
      },
      async getAllReportsWithVDataTable () {
        const query = {
          l: this.options.itemsPerPage,
          lo: this.options.page,
          sf: this.options.sortBy[0] ? this.options.sortBy[0] : 'date',
          so: this.options.sortDesc[0] ? 'asc' : 'desc',
          day: this.selectedDate ? this.selectedDate : undefined,
          search: { search: this.reportSearch },
        }

        const companyId = this.$route.params.id
        const userUUID = this.$route.params.uuid
        if (userUUID) {
          reportService.getReportsByUserUUID(this.$store, query, userUUID)
        } else if (companyId) {
          reportService.getReportsAsAdminByCompany(this.$store, companyId, query)
        } else if (this.dashboardMode) {
          reportService.getReportsForDashboard(this.$store, query)
        } else if (this.adminReportsMode) {
          reportService.getReportsAsAdmin(this.$store, query)
        } else {
          this.$store.dispatch('reportStore/getList', query)
        }
      },
      async getCompanyVehicles () {
        if (this.company) {
          const query = {
            l: 10,
            lo: 1,
            sf: '',
            so: 'asc',
            search: { search: '' },
          }
          this.$store.dispatch('companyVehicleStore/getList', query)
        }
      },
      clickAddReport () {
        this.$router.push('/app/reports/new')
      },
      clickViewReport (id: string) {
        this.$router.push(`/app/reports/${id}`)
      },
      async getReportData () {
        this.breadcrumbItems = []
        const id = this.$route.params.rid

        if (id) {
          await axios.get(`/api/report/${id}`).then((result) => {
            if (result.data) {
              this.report = result.data
              if (this.report) {
                this.breadcrumbItems = [
                  {
                    text: this.$t('reports'),
                    to: '/app/reports',
                    exact: true,
                  },
                  {
                    text: `${
                      this.report.companyVehicleObject
                        ? this.report.companyVehicleObject.owner + ' - ' + this.report.companyVehicleObject.vin
                        : this.report.name
                    }`,
                  },
                ]
              }
            }
          })
        } else {
          this.report = undefined
        }
      },
      reportIsValid (report: IReport) {
        let valid = true
        if (report.questions) {
          for (const q of report.questions) {
            if (!q.valid) {
              valid = false
            }
          }
        }

        return valid
      },
      processCanCreateReports () {
        axios.get('/api/myselfCanCreateReports').then((result) => {
          if (result.data) {
            this.canCreateReports = result.data.canCreateReports
          }
        })
      },
      clickDeleteReport (report: IReport) {
        this.$confirm(
          `${this.$t('dialog.do-you-really-want-the-report-to')}
<strong>${report.name}</strong>
${this.$t('dialog.delete')}`,
          {
            color: 'error',
            buttonTrueText: this.$t('yes').toString(),
            buttonFalseText: this.$t('no').toString(),
            title: this.$t('tooltip.delete-report').toString(),
          },
        ).then((res) => {
          if (res) {
            if (report._id) {
              this.deleteReport({ id: report._id, item: report }).then(() => {
                this.getAllReportsWithVDataTable()
              })
            } else {
              console.error('Tried to delete vehicle without id, this should not happen')
            }
          }
        })
      },
    },
  })
