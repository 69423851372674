var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"reports-tables","fluid":"","tag":"section"}},[(_vm.breadcrumbItems.length)?_c('v-breadcrumbs',{attrs:{"divider":"/","items":_vm.breadcrumbItems}}):_vm._e(),((_vm.company || _vm.userIsSystemAdmin))?_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"indigo","icon":"mdi-chart-box-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"mobile-card-heading"},[_vm._v(" "+_vm._s(_vm.$t('reports'))+" ")])]},proxy:true}],null,false,1505526059)},[(!_vm.report)?_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[(!_vm.dashboardMode)?_c('v-row',{staticStyle:{"justify-content":"right"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"responsive-table-search-input",attrs:{"label":_vm.$t('date'),"readonly":"","append-icon":"mdi-calendar","value":_vm.selectedDate,"clearable":""},model:{value:(_vm.dateInput),callback:function ($$v) {_vm.dateInput=$$v},expression:"dateInput"}},on))]}}],null,false,1353463005),model:{value:(_vm.datePickerVisible),callback:function ($$v) {_vm.datePickerVisible=$$v},expression:"datePickerVisible"}},[_c('v-date-picker',{attrs:{"locale":"de","no-title":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),_c('v-text-field',{staticClass:"responsive-table-search-input",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":"","single-line":""},model:{value:(_vm.reportSearch),callback:function ($$v) {_vm.reportSearch=$$v},expression:"reportSearch"}})],1):_vm._e(),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"responsive-table",attrs:{"options":_vm.options,"server-items-length":_vm.totalReports ? _vm.totalReports : _vm.reports.length,"headers":_vm.userIsSystemAdmin ? _vm.reportAdminHeaders : _vm.reportHeaders,"items":_vm.reports,"footer-props":{
            'items-per-page-options': [10, 20, 50, 100],
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',{attrs:{"data-label":_vm.$t('date')}},[_vm._v(" "+_vm._s(_vm._f("formatDateForReport")(row.item.date))+" ")]),(_vm.userIsSystemAdmin)?_c('td',{attrs:{"data-label":_vm.$t('company')}},[_vm._v(" "+_vm._s(row.item.companyObject.name)+" ")]):_vm._e(),_c('td',{attrs:{"data-label":_vm.$t('tester')}},[_vm._v(" "+_vm._s(_vm._f("fullNameOfUser")(row.item.userObject))+" ")]),_c('td',{attrs:{"data-label":_vm.$t('licence-plate')}},[_vm._v(" "+_vm._s(row.item.companyVehicleObject ? row.item.companyVehicleObject.licencePlate : '-')+" ")]),_c('td',{attrs:{"data-label":_vm.$t('owner')}},[_vm._v(" "+_vm._s(row.item.companyVehicleObject ? row.item.companyVehicleObject.owner : '-')+" ")]),_c('td',{attrs:{"data-label":_vm.$t('type')}},[_vm._v(" "+_vm._s(_vm.$t(row.item.type.toLowerCase()))+" ")]),_c('td',{staticStyle:{"text-align":"right"},attrs:{"data-label":_vm.$t('valid')}},[(_vm.reportIsValid(row.item))?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle ")])],1),_c('td',{staticStyle:{"text-align":"right"},attrs:{"data-label":_vm.$t('actions')}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(row.item._id)?_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"indigo"},on:{"click":function($event){return _vm.clickViewReport(row.item._id)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-eye ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.view-report')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(row.item._id && _vm.userIsSystemAdmin)?_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.clickDeleteReport(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.delete-report')))])])],1)])]}}],null,false,3317913535)}),_c('v-divider'),(!_vm.userProfileRouteIsUsed)?_c('v-row',{staticClass:"mx-auto py-6",attrs:{"justify":"end","align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(!_vm.disableCreateReports)?{key:"activator",fn:function(ref){
          var on = ref.on;
return [(_vm.totalVehicles == 0)?_c('p',{staticStyle:{"color":"darkred","font-weight":"bold","margin":"8px"}},[_vm._v(" "+_vm._s(_vm.$t('dialog.you-can-only-add-reports-if-you-have-company-vehicles'))+" ")]):(!_vm.canCreateReports)?_c('p',{staticStyle:{"color":"darkred","font-weight":"bold","margin":"8px"}},[_vm._v(" "+_vm._s(_vm.$t('tooltip.no-certificate-to-create-report'))+" ")]):_vm._e(),(_vm.canCreateReports)?_c('v-btn',_vm._g({style:(_vm.totalVehicles == 0 ? 'opacity: .4' : ''),attrs:{"color":"green","fab":"","small":""},on:{"click":function($event){_vm.totalVehicles > 0 ? _vm.clickAddReport() : ''}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1):_c('v-btn',_vm._g({staticStyle:{"opacity":"0.5"},attrs:{"fab":"","small":""}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.canCreateReports ? _vm.$t('tooltip.add-report') : _vm.$t('tooltip.no-certificate-to-create-report')))])])],1):_vm._e()],1)],1):_c('div',[_c('report-details',{attrs:{"report":_vm.report}})],1)],1):_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('p',{staticStyle:{"font-size":"1.3em"}},[_vm._v(" "+_vm._s(_vm.$t('dialog.you-are-not-a-member-of-a-company'))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }